import Link from "next/link";
import { FaHome } from "react-icons/fa";

export type BreadcrumbElementType = {
  label?: string;
  href?: string;
};

export const Breadcrumbs = ({
  breadcrumbs,
}: {
  breadcrumbs: BreadcrumbElementType[];
}) => (
  <ol className="m-0 d-flex justify-content-start align-items-center ps-0">
    {(breadcrumbs ?? []).map((breadcrumb, index) => {
      const elementBody = index ? (
        <span>{breadcrumb?.label ?? "Element"}</span>
      ) : (
        <FaHome className="my-2 text-secondary d-flex align-items-center" />
      );
      return (
        <div key={index} className="d-flex align-items-center">
          <li className="d-flex align-items-center">
            {breadcrumb?.href && index < breadcrumbs.length - 1 ? (
              <Link
                href={breadcrumb.href}
                prefetch={true}
                passHref
                className="d-flex text-truncate align-items-center fs-7">
                {elementBody}
              </Link>
            ) : (
              elementBody
            )}
          </li>
          {index < breadcrumbs.length - 1 ? (
            <li
              key={index}
              style={{ margin: "0 0.35rem" }}
              className="d-flex text-secondary align-items-center fs-7">
              /
            </li>
          ) : null}
        </div>
      );
    })}
  </ol>
);
